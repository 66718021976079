/**
 *  @author Eugene Terentev <eugene@terentev.net>
 */
$(function () {

    let topBarHeight = 20;
    let topMenuBarHeight = 20;
    let topBarHeightForMenu = 83;
    let $top = $('.top-header');
    let $frontTop = $('.front-header-menu-line');
    let $menu = $('.menu-bar');
    let $sidebarCollapse = $('#sidebarCollapse');
    let $mfp = $('.mfp-content');

    $(window).scroll(function (event) {
        var scroll = $(window).scrollTop();
        if (scroll >= topBarHeight) {
            $top.css({
                position: 'fixed',
                width: '100%',
                backgroundColor: '#000000b8'
            });
        } else {
            $top.css({
                position: 'inherit',
                width: 'auto',
                backgroundColor: '#000'
            });
        }
        if (scroll >= topBarHeightForMenu) {
            $menu.stop().fadeIn(10);
            $sidebarCollapse.css({
                display: 'none'
            })
        } else {
            $menu.fadeOut(0);
            $sidebarCollapse.css({
                display: 'inherit'
            })
        }
        $(document).on("click", "#sidebarCollapse2", function () {
            $sidebarCollapse.click();
        });


    });

    $(window).scroll(function (event) {
        var scroll = $(window).scrollTop();
        if (scroll >= topMenuBarHeight) {
            $frontTop.css({
                position: 'fixed',
                width: '100%',
                top: '29px'
            });
        } else {

        }
        if (scroll >= topBarHeightForMenu) {
            $frontTop.stop().fadeIn(10);
        }


    });


    $(window).ready(function () {
        let topOffset = $(window).scrollTop();
        if (topOffset >= topMenuBarHeight) {
            $frontTop.css({
                position: 'fixed',
                width: '100%',
                top: '29px'
            });
        }
        if (topOffset >= topBarHeight) {
            $top.css({
                position: 'fixed',
                width: '100%',
                backgroundColor: '#000000b8'
            });
        } else {
            $top.css({
                position: 'inherit',
                width: 'auto',
                backgroundColor: '#000'
            });
        }
        if (topOffset >= topBarHeightForMenu) {
            $menu.stop().fadeIn();
            $sidebarCollapse.css({
                display: 'none'
            })
        } else {
            $menu.fadeOut();
            $sidebarCollapse.css({
                display: 'inherit'
            })
        }
    });


    //filters on change
    $(document).on("change", ".sorting-items", function () {
        let _this = $(this);
        let value = _this.val();
        let url = '?sort=' + value;
        if (value === '' || value === 0) {
            url = '?';
        }
        let fullUrl = window.location.href;
        let params = fullUrl.split('?')[1];
        if (fullUrl.indexOf("sort") > -1) { // isset

            let params2 = params.split('&');
            let str = '';
            for (let i = 0; i < params2.length; i++) {
                // console.log(params2[i]);
                if (params2[i].indexOf("sort") > -1) {
                    str += '&sort=' + value;
                } else {
                    str += '&' + params2[i];
                }
            }
            url = '?' + str.substring(1);
        } else {
            let url2 = fullUrl.split('?')[1];
            if (typeof url2 != 'undefined' && ( url2.indexOf("search") > -1 ||  url2.indexOf("page") > -1)) { // isset
                let str = '';
                let params2 = url2.split('&');
                for (let i = 0; i < params2.length; i++) {
                    str += '&' + params2[i];
                }
                str = str + '&sort=' + value;
                url = '?' + str.substring(1);
            }
        }
        window.location.href = url;
    })



//    change list view and save it

    $(document).on("click",'.list_grid-btns a',function () {
        let _this = $(this);
        let view = _this.data('view');
        document.cookie = "product_view="+view;
        // console.log(document.cookie.split('product_view=')[1]);
        return false;
    })

});

